import React from "react";
import { AspectRatio, Box } from "@chakra-ui/react";

const Video = ({ videoId, videoTitle, ...props }) => (
	<AspectRatio ratio={16 / 9} maxWidth="480px">
		<Box as="iframe"
			src={`https://www.youtube.com/embed/${videoId}`}
			title={videoTitle}
			allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
			frameBorder="0"
			webkitallowfullscreen="true"
			mozallowfullscreen="true"
			allowFullScreen
		/>
	</AspectRatio>
);

export default Video;
