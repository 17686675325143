import React, { useState, useEffect, createRef, Fragment } from "react"
import { graphql } from 'gatsby';
import styled from "styled-components"
import { gsap } from "gsap"
import Arrow from "../assets/svg/arroworange.inline.svg"
import ArrowReload from "../assets/svg/arrowreload.inline.svg"
import Close from "../assets/svg/close.inline.svg"
import Listen from "../assets/svg/listen.inline.svg"
import BigMuscle from "../assets/svg/musclebig.inline.svg"
import { Flex, Box, Image } from "@chakra-ui/react"
import SEO from "../components/seo"
import Nav from "../components/Nav"
import Video from "../components/Video"

import ToukoImage from "../assets/images/Touko-pouko_huuto.png"

const Title = styled.h1`
  font-family: "Poppins";
  font-weight: 900;
  font-style: normal;
  font-size: 2.65rem;
  line-height: 0.94;
  text-align: left;
  color: #1a1818;
  padding-bottom: 2rem;
  hyphens: auto;
  padding-top: 1rem;

  @media (min-width: 48em) {
    font-size: 3rem;
    border-top: 3px solid #fcafc0;
  }
`

const VideoContainer = styled.div`
  margin-bottom: 1.5rem;
`

const PartTitle = styled.h4`
  font-family: "Poppins";
  font-weight: 900;
  font-style: normal;
  font-size: 1rem;
  line-height: 0.94;
  color: #1a1818;
`

const VideoTitle = styled.h2`
  font-family: "Poppins";
  font-size: 2rem;
  font-weight: 900;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: normal;
  text-align: left;
  color: #1a1818;
`

export default function RohkaisuPage({ data: { page } }) {
  const paddingTopIfFirstVideoNameMissing = page.toukopoukoVideos?.videot[0]
    .videoName
    ? "136px"
    : "106px"
  return (
    <>
      <Nav />
      <SEO title={page.seo.title} description={page.seo.metaDesc} />
      <Flex
        direction={{ base: "column-reverse", md: "row" }}
        margin="0 auto 4rem"
        px={{ base: "20px" }}
        maxWidth={{ base: "480px", md: "1000px" }}
        pt={{ base: "60px", md: 0 }}
      >
        <Box
          w={{ base: "100%", md: "50%" }}
          pt={{ md: "60px" }}
          pr={{ base: 0, md: "28px" }}
        >
          {page.toukopoukoVideos.videot.map((item, i) => (
            <VideoContainer key={`video-${i}`}>
              <Box height="10px" />
              <PartTitle>Osa {i + 1}</PartTitle>
              <VideoTitle>{item.videoName}</VideoTitle>
              <Box height="20px" />
              <Video videoId={item.videoLink} videoTitle={item.videoName} />
            </VideoContainer>
          ))}
        </Box>

        <Box
          w={{ base: "100%", md: "50%" }}
          pl={{ base: 0, md: "28px" }}
          pb={{ base: "2rem", md: 0 }}
          pt={{ base: 0, md: paddingTopIfFirstVideoNameMissing }}
          borderLeft={{ base: 0, md: "3px solid #fcafc0" }}
        >
          <Title>{page.frontpageLinkTitle.styledTitle}</Title>
          <Box boxSize="sm">
            <Image src={ToukoImage} alt="Touko-Pouko" />
          </Box>
        </Box>
      </Flex>
    </>
  )
}

export const query = graphql`
  query {
    page: wpPage(slug: { eq: "touko-poukon-paskarteluvinkit" }) {
      slug
      title
      seo {
        metaDesc
        title
      }
      toukopoukoVideos {
        videot {
          videoName
          videoLink
        }
      }
      content
      frontpageLinkTitle {
        styledTitle
      }
    }
  }
`
